import Header from "./sections/Header";
import Footer from "./sections/Footer";
import Pricing from "./sections/Pricing";
import Features from "./sections/Features";
import Banner from "./sections/Banner";
export default function App() {
  return (
    <>
      <Header />
      <Banner />
      <Features />
      <Pricing />
      <Footer />
    </>
  );
}
