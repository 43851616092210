import { CheckIcon } from "@heroicons/react/20/solid";

const accountantServices = [
  {
    name: "Simple",
    value: 1.5,
    features: [
      "Formulario 29",
      "Una asesoría mensual por Whatsapp o Correo",
      "No incluye renta",
    ],
  },
  {
    name: "Semi",
    value: 5,
    features: [
      "Formulario 29",
      "Consultas ilimitadas por Whatsapp o Correo respuesta hasta 72 horas hábiles",
      "No incluye renta",
    ],
  },
  {
    name: "Full",
    value: 10,
    features: [
      "Formulario 29",
      "Consultas ilimitadas por Whatsapp, Correo o Teléfono",
      "Una reunión semanal de 1 hora",
      "No incluye renta",
    ],
  },
];
const includedFeatures2 = [
  "Cálculo de liquidaciones de sueldo",
  "Cálculo de imposiciones y carga en PREVIRED",
  "Tramitación de licencias médicas",
  "Cálculo y elaboración de finiquito - 1 UF por evento",
  "Elaboración de contratos de trabajo - 1 UF por evento",
];
const includedFeatures3 = [
  "Emisión de facturas",
  "Control de cuentas por cobrar",
  "Control de cuentas por pagar",
  "Pago de proveedores",
  "Pago de remuneraciones",
];
const includedFeatures4 = [
  "Conciliación bancaria",
  "Estado de Resultados Mensual",
  "Balance 8 columnas Mensual",
  "Balance 8 columnas Anual",
  "Incluye declaración formulario 29",
];

export default function Pricing() {
  return (
    <>
      <div className="bg-white py-24 sm:py-32" id="pricing">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Tarifas
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600"></p>
          </div>
          {accountantServices.map((service) => (
            <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
              <div className="p-8 sm:p-10 lg:flex-auto">
                <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                  Asesoría contable {service.name}
                </h3>
                <p className="mt-6 text-base leading-7 text-gray-600"></p>
                <div className="mt-10 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-blue-600">
                    ¿Qué incluye?
                  </h4>
                  <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul
                  role="list"
                  className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                >
                  {service.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-blue-600"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                  <div className="mx-auto max-w-xs px-8">
                    <p className="text-base font-semibold text-gray-600">
                      Tarifa mensual
                    </p>
                    <p className="mt-6 flex items-baseline justify-center gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">
                        {service.value}
                      </span>
                      <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                        UF + IVA
                      </span>
                    </p>
                    <a
                      href="https://wa.me/56935461485"
                      className="mt-10 block w-full rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Contrata ahora
                    </a>
                    <p className="mt-6 text-xs leading-5 text-gray-600">
                      Sin contratos ni cobros sorpresa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                Servicio de Balance y EERR
              </h3>
              <p className="mt-6 text-base leading-7 text-gray-600"></p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-blue-600">
                  ¿Qué incluye?
                </h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
                {includedFeatures4.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-blue-600"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">
                    Tarifa Mensual por cada 10 DTE
                  </p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">
                      1
                    </span>
                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                      UF + IVA
                    </span>
                  </p>
                  <a
                    href="https://wa.me/56935461485"
                    className="mt-10 block w-full rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Contrata ahora
                  </a>
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    Un DTE (Documento Tributario Electrónico) se considera
                    facturas de compra o venta, boletas de honorarios, notas de
                    crédito, notas de débito, etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                Recursos Humanos
              </h3>
              <p className="mt-6 text-base leading-7 text-gray-600"></p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-blue-600">
                  ¿Qué incluye?
                </h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
                {includedFeatures2.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-blue-600"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">
                    Tarifa por trabajador
                  </p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">
                      0.5
                    </span>
                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                      UF + IVA
                    </span>
                  </p>
                  <a
                    href="https://wa.me/56935461485"
                    className="mt-10 block w-full rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Contrata ahora
                  </a>
                  <p className="mt-6 text-xs leading-5 text-gray-600">
                    Incluye Talana. <br></br>
                    Sobre 100 trabajadores, 0.3 UF + IVA por trabajador.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <div className="p-8 sm:p-10 lg:flex-auto">
              <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                Administración
              </h3>
              <p className="mt-6 text-base leading-7 text-gray-600"></p>
              <div className="mt-10 flex items-center gap-x-4">
                <h4 className="flex-none text-sm font-semibold leading-6 text-blue-600">
                  ¿Qué incluye?
                </h4>
                <div className="h-px flex-auto bg-gray-100" />
              </div>
              <ul
                role="list"
                className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
              >
                {includedFeatures3.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className="h-6 w-5 flex-none text-blue-600"
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
              <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                <div className="mx-auto max-w-xs px-8">
                  <p className="text-base font-semibold text-gray-600">
                    Tarifa desde
                  </p>
                  <p className="mt-6 flex items-baseline justify-center gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-gray-900">
                      30
                    </span>
                    <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                      UF + IVA
                    </span>
                  </p>
                  <a
                    href="https://wa.me/56935461485"
                    className="mt-10 block w-full rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Contrata ahora
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
