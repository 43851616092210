const features = [
  {
    name: "Contabilidad",
    description:
      "Somos Partner de Uwigo, el software de contabilidad más fácil de usar. Te ayudamos a llevar tu contabilidad de manera eficiente y sin errores.",
    icon: "uwigo.png",
  },
  {
    name: "Recursos Humanos",
    description:
      "Trabajamos con el software más completo de remuneraciones del mercado. Talana nos permite gestionar de manera eficiente los procesos de remuneraciones, contratos, vacaciones, licencias médicas, entre otros.",
    icon: "talana.png",
  },
  {
    name: "Renta",
    description:
      "Paga tus impuestos de manera fácil y rápida. Nos encargamos de realizar la declaración de renta de tu empresa, y de pagar los impuestos correspondientes",
    icon: "renta.png",
  },
  {
    name: "Administración",
    description:
      "Te ayudamos a gestionar tus facturas, boletas, y todo lo que necesites para que tu negocio funcione de manera eficiente",
    icon: "admin.png",
  },
];
export default function Features() {
  return (
    <div className="bg-white py-24 sm:py-32" id="product">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-blue-600">
            Mantén todo bajo control y en orden con el SII
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Olvídate de pagos de multas o formularios atrasados
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Dedícate a hacer crecer tu negocio, nosotros nos encargamos de
            apoyarte en tener todo en regla
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-16 items-center justify-center rounded-lg bg-blue-600">
                    <img src={feature.icon} alt="" className="w-14" />
                  </div>
                  &nbsp;{feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
